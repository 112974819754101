@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* .outline input:focus-within~label,
.outline input:not(:placeholder-shown)~label{
  @apply transform scale-75 -translate-y-4 z-0 px-1 py-0;
} */

.input:focus~label {
  --tw-text-opacity: 1;
  color: #0066ff;
  left: 0px;
}

.icon-group-1 {
  background: linear-gradient(180deg, rgba(246, 111, 119, 1) 23%, rgba(158, 113, 191, 1) 100%);
}

.icon-group-2 {
  background: linear-gradient(180deg, rgba(158, 113, 191, 1) 33%, rgba(119, 91, 224, 1) 100%);
}

.icon-group-3 {
  background: linear-gradient(180deg, rgba(119, 91, 224, 1) 33%, rgba(90, 129, 250, 1) 100%);
}


.sticky-type-ahead:focus+label.placeholder,
.async-type-ahead:not(:placeholder-shown)+label.placeholder {
  top: 0.5rem;
  left: 0.2rem;
  padding: 0 0.25rem;
  transition: all 0.1s ease-in-out;
  z-index: 10;
}

#react-select-2-input, 
.css-qbdosj-Input input:focus,
.vi:focus,
.sticky-type-ahead .css-10rfoy8-Input:focus {
  border: none !important;
  --tw-ring-color: none;
  --tw-ring-shadow: none;
}

.sticky-type-ahead .css-1nmdiq5-menu {
  margin: 0 !important;
}

.sticky-type-ahead .css-1jqq78o-placeholder,
.sticky-type-ahead .css-1dimb5e-singleValue {
  margin-top: 0.5rem;
}

.vi__wrapper, .vi__container{
  width: 100% !important;
}

.vi:focus{
  --tw-ring-shadow: none;
}

#loan-agreement .offer-summary {
  page-break-before: always;
  font-family: 'Times New Roman', Times, serif;
  margin: 20px 0;
  font-size: 16px;
}

#loan-agreement .schedule {
  text-align: center;
  font-weight: bold;
  font-size: 24px;
}

#loan-agreement table.offer-summary {
  border-collapse: collapse;
  margin: 10px 0;
}

#loan-agreement table.offer-summary tr td {
  border: 2px solid black;
  padding: 10px;
}


#loan-agreement .offer-summary-title {
  text-align: center;
  width: 23%;
}

#loan-agreement .offer-summary-description {
  text-align: left;
  width: 54%;
}

#loan-agreement table.offer-summary-itemization {
  border-collapse: collapse;
  border: 3px solid black;
  width: 100%;
}

#loan-agreement .offer-summary-itemization th, .offer-summary-itemization td {
  border: 3px solid black;
  padding: 10px;
  text-align: left;
}

#loan-agreement .offer-summary-itemization th {
  text-align: center;
}

#loan-agreement .offer-summary-itemization thead {
  font-weight: bold;
}

input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;/*your box-shadow*/
  -webkit-text-fill-color: #333;
} 

@media (max-width: 375px) {
  .sticky-type-ahead .css-1jqq78o-placeholder,
  .sticky-type-ahead .css-1dimb5e-singleValue {
    margin-top: 0.9rem;
  }
}

/* Overrides for the react-credit-card VC display */
.rccs__issuer {
  visibility: hidden;
}
.rccs__chip {
  background: none;
}
.rccs__card__background {
  background: url('./lib/images/vc_card_bg_visa.png') !important;
  background-size: contain !important;
  height: 100% !important;
  width: 100% !important;
  transform: none !important;
  position: unset !important;
}

.rccs__number {
  top: 35%;
}

.rccs__name {
  bottom: 10%;
  left: 5%;
  font-size: 15px;
}

.rccs__expiry {
  bottom: 25%;
}

.rccs__stripe {
  top: 7%;
}
